import React, { useEffect, useRef } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useNavigate } from "react-router-dom";
import AdminFooter from "../../component/Footer";

function Dashboard() {
    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    useEffect(() => {

        if (didMountRef.current) {
            if (JSON.parse(localStorage.getItem('USER_DATA')) == null) {
                Navigate("/")
            }
            else {

            }
        }
        didMountRef.current = false;
    })
    return (
        <>
            <div id="layout-wrapper">

                <AdminHeader />
                <Sidebar />
                <div class="vertical-overlay"></div>
                <div class="main-content">

                    <div class="page-content">
                        <div class="container-fluid">

                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Analytics</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a href="javascript: void(0);">Dashboards</a></li>
                                                <li class="breadcrumb-item active">Analytics</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                    <AdminFooter />
                </div>


            </div>
        </>
    )
}
export default Dashboard