
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminHome from './Admin/container/Home';
import Dashboard from './Admin/container/Dashboard/index';
import DefaultValue from "./Admin/container/DefaultValue/index";
import DefaultValueYearWise from "./Admin/container/Defaultyearwisevalue/index"
import GeneralSettings from "./Admin/container/GeneralSetting/index"
import SocialSettings from "./Admin/container/SocialSetting/index"
import Newsletter from './Admin/container/Newsletter';
import { ToastContainer } from 'react-toastify';
import Newsblogs from './Admin/container/Newsblogs';
import AddNewBlog from './Admin/container/Newsblogs/add-new-blog';
import AddBlogsCategory from './Admin/container/Newsblogs/add-categories';
import 'react-toastify/dist/ReactToastify.css';
import AddBlogsTags from './Admin/container/Newsblogs/add-tags';
import AdminFaq from './Admin/container/Faq';
import FooterSection from './Admin/container/Appearance/footer';
import AddNewPages from './Admin/container/Pages/add-new-page';
import AllPages from './Admin/container/Pages/all-pages';
import Customer from './Admin/container/Customer';
import CustomerDetail from './Admin/container/Customer/customerdetail'
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
        <Route exact path='/' activeClassName="active" element={<AdminHome />} />
          <Route exact path='/csadmin/dashboard' activeClassName="active" element={<Dashboard />} />
          <Route exact path='/csadmin/customer' activeClassName="active" element={<Customer />} />
          <Route exact path='/csadmin/customer/:id' activeClassName="active" element={<CustomerDetail />} />
          <Route exact path='/csadmin/add-new-faq' activeClassName="active" element={<AdminFaq />} />
          <Route exact path='/csadmin/news-blogs' activeClassName="active" element={<Newsblogs />} />
          <Route exact path='/csadmin/add-new-blogs' activeClassName="active" element={<AddNewBlog />} />
          <Route exact path='/csadmin/add-new-pages' activeClassName="active" element={<AddNewPages />} />
          <Route exact path='/csadmin/all-pages' activeClassName="active" element={<AllPages />} />
          <Route exact path='/csadmin/add-new-blogs/:slug' activeClassName="active" element={<AddNewBlog />} />
          <Route exact path='/csadmin/add-new-pages/:slug' activeClassName="active" element={<AddNewPages />} />
          <Route exact path='/csadmin/defaultvalue' activeClassName="active" element={<DefaultValue />} />
          <Route exact path='/csadmin/defaultvalueyearwise' activeClassName="active" element={<DefaultValueYearWise />} />
          <Route exact path='/csadmin/general-settings' activeClassName="active" element={<GeneralSettings />} />
          <Route exact path='/csadmin/newsletter' activeClassName="active" element={<Newsletter />} />
          <Route exact path='/csadmin/social-settings' activeClassName="active" element={<SocialSettings />} />
          <Route exact path='/csadmin/news-blogs/categories' activeClassName="active" element={<AddBlogsCategory />} />
          <Route exact path='/csadmin/news-blogs/tags' activeClassName="active" element={<AddBlogsTags />} />
          <Route exact path='/csadmin/appearance/footer' activeClassName="active" element={<FooterSection />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
