
const API_URL ="https://crm.proptrail.au/"
// const API_URL='http://localhost:8000/'
const CATEGORIES = [];
const themesetting = {};
const Image_Base_Url='https://crm.proptrail.au/uploads/'
const Page_Base_URL='http://localhost:5000/'
const default_img='/img/default_img.png'
export default { API_URL, CATEGORIES, themesetting, Image_Base_Url , default_img,Page_Base_URL};

