import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';

function AdminHeader(){
    const Navigate = useNavigate()
    const didMountRef = useRef(true)
    const [show , setShow] = useState(false)
    useEffect(() => {
        if (didMountRef.current) {
            
        }
        didMountRef.current = false;
    })

   
    const  Confirm=()=>{
        localStorage.removeItem('USER_DATA');
        Navigate("/")
        
    }
    const logOut=()=>{
        setShow(true)
    }
    const Cancelalert=()=>{
        setShow(false)
    }
    return(
        <>
       <header id="page-topbar">
<div class="layout-width">
<div class="navbar-header">
    <div class="d-flex">
    
        <div class="navbar-brand-box horizontal-logo">
            <a href="index.html" class="logo logo-dark">
                <span class="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22"/>
                </span>
                <span class="logo-lg">
                    <img src="assets/images/logo-dark.png" alt="" height="17"/>
                </span>
            </a>

            <a href="index.html" class="logo logo-light">
                <span class="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22"/>
                </span>
                <span class="logo-lg">
                    <img src="assets/images/logo-light.png" alt="" height="17"/>
                </span>
            </a>
        </div>

        <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
            <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </button>
       
    </div>

    <div class="d-flex align-items-center">
        <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-toggle="fullscreen">
                <i class='bx bx-fullscreen fs-22'></i>
            </button>
        </div>

        <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                <i class='bx bx-moon fs-22'></i>
            </button>
        </div>


        <div class="dropdown ms-sm-3 header-item topbar-user">
                    <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" src="/assets/images/users/avatar-1.jpg" alt="Header Avatar"/>
                            <span class="text-start ms-xl-2">
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">Proptrail</span>
                              
                            </span>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                
                        <h6 class="dropdown-header">Welcome Proptrail!</h6>
                        <a class="dropdown-item" onClick={logOut}><i class="mdi mdi-logout text-muted fs-16 align-middle me-1" ></i> <span class="align-middle" data-key="t-logout">Logout</span></a>
                    </div>
                </div>
    </div>
</div>
</div>
</header>
<SweetAlert warning confirmBtnCssClass='alertpop' title={` Are you sure ? you want to logout `} confirmBtnText='Confirm' 
  cancelBtnText="Cancel"
 show={show} onConfirm={Confirm} onCancel={Cancelalert}  btnSize="md"   showCancel
 cancelBtnBsStyle="danger"
>
</SweetAlert>   
        </>
    )
}
export default AdminHeader