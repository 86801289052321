import React, { useEffect, useState, useRef } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Badge from 'react-bootstrap/Badge';
import constant from "../../component/Services/constant";
const AllPages = () => {
    const [pagesdatadata, setpagesdatadata] = useState([])
    const [showResetBtn, setShowRestBtn] = useState(false)
    const [Query, setQuery] = useState('')
    const navigate = useNavigate()
    let apiServices = new ApiService();
    const didMountRef = useRef(true)
    const {
        register,
        handleSubmit,
        watch,
        setError,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();
    useEffect(() => {

        if (didMountRef.current) {
            getallpageslist()
        }
        didMountRef.current = false;
    }, [])

    const getallpageslist = () => {
        apiServices.getallnewpagesrequest().then(res => {
            if (res?.data?.status == "success") {
                setpagesdatadata(res.data.PagesData)

            }
        }).catch((error) => { })
    }

    const handlesearchSubmit = () => {
        if (Query.length > 2) {
            apiServices.getsearchpagedatarequest(Query).then((res) => {
                if (res?.data?.status == "success") {
                    setpagesdatadata(res.data.PagesData)
                    setShowRestBtn(true)

                }
                else {
                    toast.error(res.data.message)
                }
            }).catch((error) => { })
        }
    }
    const removepages = (itemid) => {
        const dataString = {
            _id: itemid
        }
        const confirmed = window.confirm("Are you sure you want to delete this page");
        if (confirmed) {
            apiServices.getnewpagedeleterequest(dataString).then((res) => {
                if (res?.data?.status == "success") {
                    toast.success("page has been Deleted successfully")
                    getallpageslist()
                }
                else {
                    toast.error(res.data.message)
                }
            }).catch((error) => {

            })
        }
    }
    const changestatus = (data) => {
        const requestBody = {
            page_title: data.page_title,
            page_url: data.page_url,
            page_header_img: data.page_header_img,
            page_meta_keyword: data.page_meta_keyword,
            page_meta_description: data.page_meta_description,
            page_meta_title: data.page_meta_title,
            page_content: data.page_content,
            page_show_title: data.page_show_title,
            page_show_breadcrumbs: data.page_show_breadcrumbs,
            page_status: data.page_status == 1 ? 0 : data.page_status == 0 ? 1 : 1,
            page_id: data._id,
            page_slug:data.page_slug
        };
        apiServices.getaddnewpagesrequest(requestBody).then(res => {
            if (res.data.status === "success") {
                getallpageslist()
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    }

    const onResetclick = () => {
        setQuery('')
        getallpageslist()
        setTimeout(() => {
            setShowRestBtn(false)
        }, 2000)
    }


    return (<>
        <ToastContainer />
        <div id="layout-wrapper">

            <AdminHeader />
            <Sidebar />

            <div class="main-content">

                <div class="page-content">
                    <div class="container-fluid">


                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <div>
                                        <h4 class="mb-sm-0">Manage Pages</h4>
                                        <ol class="breadcrumb mt-2">
                                            <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                            <li class="breadcrumb-item active">Pages</li>
                                        </ol>
                                    </div>

                                    <div class="page-title-right">
                                        <div class="col-lg-12">
                                            <div class="text-end">
                                                <button class="btn btn-primary" onClick={() => { navigate('/csadmin/add-new-pages') }}>Add New</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">

                                    <div class="card-body">
                                        <div class="input-group">
                                            <input type="text" class="form-control form-control-lg" placeholder="Search" aria-label="Example text with two button addons" value={Query} onChange={(e) => { setQuery(e.target.value) ; setShowRestBtn(false) }} />
                                            <button class="btn btn-primary" type="button" onClick={() => { handlesearchSubmit() }}>Search</button>
                                            {showResetBtn ? <>
                                                <button class="btn btn-success" type="button" onClick={() => { onResetclick() }}>Reset</button>
                                            </> : ''}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">

                                    <div class="card-body">
                                        <div class="live-preview">
                                            {pagesdatadata && pagesdatadata.length > 0 ? <>

                                                <table class="table table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">S.No</th>
                                                            <th scope="col">Page Name</th>
                                                            <th scope="col">Page Url</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {pagesdatadata?.map((items, index) => {
                                                            return (<>

                                                                <tr>
                                                                    <td class="fw-semibold">{index + 1}</td>
                                                                    <td>{items?.page_title}</td>
                                                                    <td><a href={constant.Page_Base_URL + items?.page_url} target='new'>{constant.Page_Base_URL + items?.page_url}</a></td>
                                                                    <td >{items?.page_status == 0 ?
                                                                        <Badge bg="danger" onClick={() => { changestatus(items) }}>Inactive</Badge> : items.page_status == 1 ? <Badge bg="success" onClick={() => { changestatus(items) }}>Active</Badge> : ''}</td>
                                                                    <td className=" text-warning fs-5"> <i className="mdi mdi-lead-pencil" onClick={() => { navigate(`/csadmin/add-new-pages/${items.page_slug}`) }}></i> <i class="mdi mdi-trash-can" onClick={() => { removepages(items) }}></i> </td>

                                                                </tr>
                                                            </>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </> : <><h6>No Page Found</h6></>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >

        </div >
        {/* <SweetAlert warning confirmBtnCssClass='alertpop' title={` Are you sure ? you want to delete the newsletter record `} confirmBtnText='Confirm'
                cancelBtnText="Cancel"
                show={show} onConfirm={Confirm} onCancel={Cancelalert} btnSize="md" showCancel
                cancelBtnBsStyle="danger"
            >
            </SweetAlert> */}

    </>)
}
export default AllPages