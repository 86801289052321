import React, { useEffect, useState,useRef } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function SocialSettings() {
    const [filterfieldvalue, setfilterfieldvalue] = useState({
        typename: "",
        year: "",
        fieldvalue: ""
    })
    const [adminId, setadminId] = useState()
    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    let apiServices = new ApiService();
    const {
        register,
        handleSubmit,
        watch,
        setError,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        const dataString={
            admin_facebook_url:data?.admin_facebook_url,
            admin_instagram_url:data?.admin_instagram_url,
            admin_twitter_url:data?.admin_twitter_url,
            admin_youtube_url:data?.admin_youtube_url,
            admin_linkedin_url:data?.admin_linkedin_url,
            admin_pinterest_url:data?.admin_pinterest_url,
            admin_password:"",
            admin_id:adminId
            
          }
       
        apiServices.getadmingensociasettingPostRequest(dataString).then(res => {
            if (res.data.status == "success") {
                
                toast.success(res?.data?.message)
                // setTimeout(()=>{
                //     Navigate("/csadmin/dashboard")
                // },2000)

            }

        })
    }
    useEffect(() => {
        if (didMountRef.current) {
         
            apiServices.getadminsettingdataGetRequest().then(res => {
                if (res.data.status == "success") {
                    setadminId(res?.data?.data?._id)
                    const setfieldvalue = res?.data?.data
                    
                    setValue("admin_pinterest_url", setfieldvalue?.admin_pinterest_url)
                    setValue("admin_linkedin_url", setfieldvalue?.admin_linkedin_url)
                    setValue("admin_youtube_url", setfieldvalue?.admin_youtube_url)
                    setValue("admin_twitter_url", setfieldvalue?.admin_twitter_url)
                    setValue("admin_instagram_url", setfieldvalue?.admin_instagram_url)
                    setValue("admin_facebook_url", setfieldvalue?.admin_facebook_url)
                   
                }
                else {
                    setValue("admin_pinterest_url", "")
                    setValue("admin_linkedin_url", "")
                    setValue("admin_youtube_url", "")
                    setValue("admin_twitter_url", "")
                    setValue("admin_instagram_url", "")
                    setValue("admin_facebook_url", "")
                    
                }


            })
        }
        didMountRef.current = false;
    }, [])

    return (
        <>
            <ToastContainer />
            <div id="layout-wrapper">

                <AdminHeader />
                <Sidebar />

                <div class="main-content">

                    <div class="page-content">
                        <div class="container-fluid">


                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Social Settings</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                                <li class="breadcrumb-item active">Social Settings</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Values</h4>
                                            <div class="flex-shrink-0">

                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div class="row gy-4">
                                                        <div class="col-lg-12">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Facebook Url</label>
                                                                <input type="text" class="form-control" name="admin_facebook_url"  {...register("admin_facebook_url", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_facebook_url && errors.admin_facebook_url.type === "required" && (
                                                                    <small className="text-danger">FaceBook Url is required.</small>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Instagram Url</label>
                                                                <input type="text" class="form-control" name="admin_instagram_url"  {...register("admin_instagram_url", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.instagramurl && errors.instagramurl.type === "required" && (
                                                                    <small className="text-danger">Instagram Url is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Twitter Url</label>
                                                                <input type="text" class="form-control" name="admin_twitter_url"  {...register("admin_twitter_url", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.twitterurl && errors.twitterurl.type === "required" && (
                                                                    <small className="text-danger">Twitter Url is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Youtube Url</label>
                                                                <input type="text" class="form-control" name="admin_youtube_url"  {...register("admin_youtube_url", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.youtubeurl && errors.youtubeurl.type === "required" && (
                                                                    <small className="text-danger">Youtube Url is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Linkdin Url</label>
                                                                <input type="text" class="form-control" name="admin_linkedin_url"  {...register("admin_linkedin_url", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.linkdienurl && errors.linkdienurl.type === "required" && (
                                                                    <small className="text-danger">Linkdin Url is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Pinterest Url</label>
                                                                <input type="text" class="form-control" name="admin_pinterest_url"  {...register("admin_pinterest_url", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.pinteresturl && errors.pinteresturl.type === "required" && (
                                                                    <small className="text-danger">Pinterest Url is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="text-end">
                                                                <button type="submit" class="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div >

            </div >
        </>
    )
}
export default SocialSettings