import React, { useEffect, useState } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function DefaultValueYearWise() {
    const [filterfieldvalue, setfilterfieldvalue] = useState({
        typename: "",
        year: "",
        fieldvalue: ""
    })
    const [showvaluebox, setshowvaluebox] = useState(false)
    const Navigate = useNavigate()
    let apiServices = new ApiService();
    const {
        register,
        handleSubmit,
        watch,
        setError,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        const datastring = {
            default_yearwise_data: {
                name: data?.typename,
                year: data?.year,
                value: data?.fieldvalue,
            },
        }
        apiServices.postyearwisedefaultvalues(datastring).then(res => {
            if (res.data.status == "success") {
                toast.success(res?.data?.message)
                // setTimeout(()=>{
                //     Navigate("/csadmin/dashboard")
                // },2000)
               
            }

        })
    }
    useEffect(() => {
        if (filterfieldvalue?.year !== "" && filterfieldvalue?.typename !== "") {
            setshowvaluebox(true)
            if (filterfieldvalue?.typename !== "" && filterfieldvalue?.year !== "") {
                const datastring = {
                    name: filterfieldvalue?.typename,
                    year: filterfieldvalue?.year
                }
                apiServices.getfinddefaultVyrwise(datastring).then(res => {
                    if (res.data.status == "success") {
                        const setfieldvalue = res?.data?.defaultvalues?.default_yearwise_data?.value
                        setValue("fieldvalue", setfieldvalue)
                    }
                    else{
                         setValue("fieldvalue", "");
                    }
                    

                })
            }
        }
    },[filterfieldvalue?.year,filterfieldvalue?.typename])
    const currentYear = new Date().getFullYear();
    const next30Years = Array.from({ length: 30 }, (_, index) => currentYear + index);
   
    return (
        <>
            <ToastContainer />
            <div id="layout-wrapper">

                <AdminHeader />
                <Sidebar />

                <div class="main-content">

                    <div class="page-content">
                        <div class="container-fluid">


                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Default Value Year Wise</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                                <li class="breadcrumb-item active">Default Value Year Wise</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Values</h4>
                                            <div class="flex-shrink-0">

                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div class="row gy-4">
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Select Type</label>
                                                                <select class="form-control" name="typename" {...register("typename", {
                                                                    required: true,

                                                                })}
                                                                    onChange={(e) => {
                                                                        setfilterfieldvalue((prevState) => ({
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        }))
                                                                        // fetchfieldifexist(e)
                                                                    }}>
                                                                    <option value=""> Select Type</option>
                                                                    <option value="inflation">Inflation</option>
                                                                    <option value="rentalgrowth">Rental growth</option>
                                                                    <option value="growthassumption">Growth Assumption</option>
                                                                  
                                                                </select>
                                                                {errors.typename && errors.typename.type === "required" && (
                                                                    <small className="text-danger">Name is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label" >Select Year</label>
                                                                <select class="form-control" name="year" {...register("year", {
                                                                    required: true,

                                                                })} onChange={(e) => {
                                                                    setfilterfieldvalue((prevState) => ({
                                                                        ...prevState,
                                                                        [e.target.name]: e.target.value,
                                                                    }))
                                                                    // fetchfieldifexist(e)
                                                                }} >
                                                                    <option value=""> Select Year</option>
                                                                    {next30Years.map((year) => (
                                                                        <option key={year} value={year}>
                                                                            {year}
                                                                        </option>
                                                                    ))}

                                                                </select>
                                                                {errors.year && errors.year.type === "required" && (
                                                                    <small className="text-danger">Year is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {showvaluebox ? <>
                                                            <div class="col-xxl-3 col-md-6">
                                                                <div>
                                                                    <label for="basiInput" class="form-label">Set value</label>
                                                                    <input type="number" class="form-control" step="any" name="fieldvalue"  {...register("fieldvalue", {
                                                                        required: true,

                                                                    })} />
                                                                      {errors.fieldvalue && errors.fieldvalue.type === "required" && (
                                                                    <small className="text-danger">Value is required.</small>
                                                                )}
                                                                </div>
                                                            </div>


                                                        </> : ""}


                                                        <div class="col-lg-12">
                                                            <div class="text-end">
                                                                <button type="submit" class="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div >

            </div >
        </>
    )
}
export default DefaultValueYearWise