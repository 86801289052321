import React, { useEffect, useState, useRef } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from "moment";

function Newsletter() {
    const didMountRef = useRef(true)
    const [newsletterdata, setnewsletterdata] = useState([])
    const [show, setShow] = useState(false)
    const [itemId, setItemid] = useState("")
    const Navigate = useNavigate()
    let apiServices = new ApiService();
    const {
        register,
        handleSubmit,
        watch,
        setError,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();


    useEffect(() => {
        if (didMountRef.current) {
            getnewsletterdetail()

        }
        didMountRef.current = false;
    }, [])

    const getnewsletterdetail = () => {
        apiServices.getnewsletterdatarequest().then(res => {
            if (res.data.status == "success") {
                setnewsletterdata(res?.data?.data)
            }
            else {
                toast.error(res?.data?.message)
            }
        })
    }

    const removenewsletter = (itemid) => {
        setShow(true)
        setItemid(itemid)

    }
    const Confirm = () => {
        const dataString = {
            _id: itemId
        }
        apiServices.getnewsletterdeleterequest(dataString).then((res) => {
            if (res?.data?.status == "success") {
                toast.success("Newsletter has been Deleted successfully")
                setShow(false)
                getnewsletterdetail()
            }
        })

    }
    const Cancelalert = () => {
        setShow(false)
    }

    return (
        <>
            <ToastContainer />
            <div id="layout-wrapper">

                <AdminHeader />
                <Sidebar />

                <div class="main-content">

                    <div class="page-content">
                        <div class="container-fluid">


                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">News Letter</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                                <li class="breadcrumb-item active">News Letter</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">

                                        <div class="card-body">
                                            <div class="live-preview">
                                                <table class="table table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">S.No</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Created At</th>
                                                            <th scope="col">Action</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {newsletterdata?.map((items, index) => {
                                                            return (<>

                                                                <tr>

                                                                    <td class="fw-semibold">{index + 1}</td>
                                                                    <td>{items?.newsletter_email}</td>
                                                                    <td>{moment(items?.created_at).format(`LL`)}</td>
                                                                    <td onClick={() => { removenewsletter(items?._id) }} className="text-danger fs-5">  <i class="mdi mdi-trash-can"></i> </td>
                                                                    
                                                                </tr>



                                                            </>)
                                                        })}


                                                    </tbody>
                                                </table>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div >

            </div >
            <SweetAlert warning confirmBtnCssClass='alertpop' title={` Are you sure ? you want to delete the newsletter record `} confirmBtnText='Confirm'
                cancelBtnText="Cancel"
                show={show} onConfirm={Confirm} onCancel={Cancelalert} btnSize="md" showCancel
                cancelBtnBsStyle="danger"
            >
            </SweetAlert>
        </>
    )
}
export default Newsletter