import React, { useEffect, useState, useRef } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function GeneralSettings() {
    const [adminId, setadminId] = useState()
    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    let apiServices = new ApiService();
    const {
        register,
        handleSubmit,
        watch,
        setError,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        if ((data?.admin_support_mobileno !== undefined) && data?.admin_support_mobileno.length !== 10) {
            toast.error("Please Enter Valid Mobile Number")
            return
        }
        if (data?.admin_watsapp_no !== "" && data?.admin_watsapp_no?.length !== 10) {
            toast.error("Please Enter Valid Watsapp Number")
            return
        }
        if (data?.admin_gst_no !== undefined && data?.admin_gst_no?.length !== 15) {
            toast.error("Please Enter Valid GST Number")
            return
        }

        const dataString = {
            admin_site_title: data?.admin_site_title,
            admin_site_address: data?.admin_site_address,
            admin_aboutus: data?.admin_aboutus,
            admin_administration_email: data?.admin_administration_email,
            admin_support_email: data?.admin_support_email,
            admin_support_mobileno: data?.admin_support_mobileno,
            admin_watsapp_no: data?.admin_watsapp_no,
            admin_gst_no: data?.admin_gst_no,
            admin_address: data?.admin_address,
            admin_id: adminId

        }

        apiServices.getadmingensettingPostRequest(dataString).then(res => {
            if (res.data.status == "success") {
                
                toast.success(res?.data?.message)
                // setTimeout(()=>{
                //     Navigate("/csadmin/dashboard")
                // },2000)

            }

        })
    }
    useEffect(() => {
        if (didMountRef.current) {

            apiServices.getadminsettingdataGetRequest().then(res => {
                if (res.data.status == "success") {
                    setadminId(res?.data?.data?._id)
                    const setfieldvalue = res?.data?.data

                    setValue("admin_address", setfieldvalue?.admin_address)
                    setValue("admin_gst_no", setfieldvalue?.admin_gst_no)
                    setValue("admin_watsapp_no", setfieldvalue?.admin_watsapp_no)
                    setValue("admin_support_mobileno", setfieldvalue?.admin_support_mobileno)
                    setValue("admin_support_email", setfieldvalue?.admin_support_email)
                    setValue("admin_administration_email", setfieldvalue?.admin_administration_email)
                    setValue("admin_aboutus", setfieldvalue?.admin_aboutus)
                    setValue("admin_site_address", setfieldvalue?.admin_site_address)
                    setValue("admin_site_title", setfieldvalue?.admin_site_title)
                }
                else {
                    setValue("admin_address", "")
                    setValue("admin_gst_no", "")
                    setValue("admin_watsapp_no", "")
                    setValue("admin_support_mobileno", "")
                    setValue("admin_support_email", "")
                    setValue("admin_administration_email", "")
                    setValue("admin_aboutus", "")
                    setValue("admin_site_address", "")
                    setValue("admin_site_title", '')
                }


            })
        }
        didMountRef.current = false;
    }, [])


    return (
        <>
            <ToastContainer />
            <div id="layout-wrapper">

                <AdminHeader />
                <Sidebar />

                <div class="main-content">

                    <div class="page-content">
                        <div class="container-fluid">


                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">General Settings</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                                <li class="breadcrumb-item active">General Settings</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Site Detail & Settings</h4>
                                          
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div class="row gy-4">
                                                        <div class="col-lg-12">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Site Title</label>
                                                                <input type="text" class="form-control" name="admin_site_title"  {...register("admin_site_title", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_site_title && errors.admin_site_title.type === "required" && (
                                                                    <small className="text-danger">Site Title is required.</small>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Site Address (URL):</label>
                                                                <input type="text" class="form-control" name="admin_site_address"  {...register("admin_site_address", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_site_address && errors.admin_site_address.type === "required" && (
                                                                    <small className="text-danger">Site Address is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">About us</label>
                                                                <textarea type="text"  class="form-control"   name="admin_aboutus"  row='5' {...register("admin_aboutus", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_aboutus && errors.admin_aboutus.type === "required" && (
                                                                    <small className="text-danger">About us is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Administration Email Address</label>
                                                                <input type="text" class="form-control" name="admin_administration_email"  {...register("admin_administration_email", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_administration_email && errors.admin_administration_email.type === "required" && (
                                                                    <small className="text-danger">Administration Email Address is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Support Email Address</label>
                                                                <input type="text" class="form-control" name="admin_support_email"  {...register("admin_support_email", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_support_email && errors.admin_support_email.type === "required" && (
                                                                    <small className="text-danger">Support Email Address is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Support Mobile Number</label>
                                                                <input type="text" class="form-control" name="admin_support_mobileno"  {...register("admin_support_mobileno", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_support_mobileno && errors.admin_support_mobileno.type === "required" && (
                                                                    <small className="text-danger">Support Mobile Number is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Watsapp Number</label>
                                                                <input type="text" class="form-control" name="admin_watsapp_no"  {...register("admin_watsapp_no", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_watsapp_no && errors.admin_watsapp_no.type === "required" && (
                                                                    <small className="text-danger">Watsapp Number is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">GST No:</label>
                                                                <input type="text" class="form-control" name="admin_gst_no"  {...register("admin_gst_no", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_gst_no && errors.admin_gst_no.type === "required" && (
                                                                    <small className="text-danger">GST Number is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-6 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Address</label>
                                                                <input type="text" class="form-control" name="admin_address"  {...register("admin_address", {
                                                                    // required: true,

                                                                })} />
                                                                {errors.admin_address && errors.admin_address.type === "required" && (
                                                                    <small className="text-danger">Address is required.</small>
                                                                )}
                                                            </div>
                                                        </div>


                                                        <div class="col-lg-12">
                                                            <div class="text-end">
                                                                <button type="submit" class="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div >

            </div >
        </>
    )
}
export default GeneralSettings