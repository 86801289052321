import AdminHeader from "../../component/Header"
import Sidebar from "../../component/Sidebar"
import { ToastContainer, toast } from 'react-toastify';
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import Badge from 'react-bootstrap/Badge';
const AddBlogsTags = () => {
    let apiServices = new ApiService();
    const didMountRef = useRef(true)
    const Navigate = useNavigate()

    const [tagId, settagId] = useState('')
    const [blogtagdata, setblogtagdata] = useState([])
    const [edititemdata,setedititemdata] = useState({})
    const {
        register,
        handleSubmit,
        watch,
        setError,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();
    const onSubmit = (data) => {

        const requestBody = {
            tag_name: data.tag_name,
        };
        if (tagId!=='') {
            requestBody.tag_id = tagId;
        }
        apiServices.getnewsblogtagsrequest(requestBody).then(res => {
            console.log(res, "response");
            if (res.data.status === "success") {
                toast.success(res?.data?.message);
                gettagslist()
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    };
    useEffect(()=>{
        if(edititemdata){
            setValue("tag_name", edititemdata?.tag_name);
        }
    },[edititemdata])

    const changestatus=(data)=>{
        const requestBody = {
            tag_name: data.tag_name,
            tag_status:!data.tag_status,
            tag_id:data._id
        };
        apiServices.getnewsblogtagsrequest(requestBody).then(res => {
            if (res.data.status === "success") {
                gettagslist()
                setValue("tag_name", '');
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    }


    useEffect(() => {

        if (didMountRef.current) {

            gettagslist()
        }
        didMountRef.current = false;

    })

    const gettagslist = () => {
        apiServices.getnewsblogtagslistrequest().then(res => {
            console.log(res)
            if (res?.data?.status == "success") {
                setblogtagdata(res.data.tags)
                setValue("tag_name", res.data.tags?.tag_name);
            }
        }).catch((error)=>{})
    }


    const removetags = (itemid) => {
        const dataString = {
            _id: itemid
        }
        apiServices.getnewsblogstagdeleterequest(dataString).then((res) => {
            if (res?.data?.status == "success") {
                toast.success("Tags has been Deleted successfully")
                gettagslist()
            }
            else if(res?.data?.status=='error'){
                toast.error(res.data.message)
            }
        }).catch((error)=>{})

    }
    return (<>
        <ToastContainer />
        <div id="layout-wrapper">

            <AdminHeader />
            <Sidebar />


            <div class="main-content">
                <div class="page-content">
                    <div class="container-fluid">

                        <div class="row" id="table-striped">
                            <div class="col-4">
                                <div class="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Add New Tags</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="live-preview">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div class="row gy-4">
                                                    <div class="col-lg-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label" >Tag Name/Title:</label><span className="text-danger">*</span>
                                                            <input type="text" class="form-control" name='tag_name'
                                                                {...register("tag_name", {
                                                                    required: true,

                                                                })}
                                                            />
                                                            {errors.tag_name && errors.tag_name.type === "required" && (
                                                                <small className="text-danger">Tag Name/Title is required.</small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="text-end">
                                                            <button type="submit" class="btn btn-primary">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="card">
                                    <div class="card-header">
                                        <h5>Tag Listings</h5>
                                    </div>
                                    <div class="card-body p-0" >

                                        <div class="live-preview">
                                            <table class="table table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Tag Name</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>


                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {blogtagdata?.map((items, index) => {
                                                            return (<>

                                                                <tr>

                                                                    <td class="fw-semibold">{index + 1}</td>
                                                                    <td>{items?.tag_name}</td>
                                                                   
                                                                    <td >{items?.tag_status == 0 ?
                                                                    <Badge bg="danger" onClick={()=>{changestatus(items )}}>Inactive</Badge> : items.tag_status == 1 ? <Badge bg="success" onClick={()=>{changestatus(items)}}>Active</Badge> : ''}</td>
     
                                                                    <td className="  text-warning fs-5"> <i className="mdi mdi-lead-pencil" onClick={() => { settagId(items._id); setedititemdata(items) }}></i> <i class="mdi mdi-trash-can" onClick={()=>{removetags(items._id)}}></i> </td>
                                                                    
                                                                </tr>



                                                            </>)
                                                        })}
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                    <div class="card-footer bg-white">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div>
                                                    <p class="mb-sm-0 mt-2">Showing 1 to 1 of 1 entries</p>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="float-sm-end">
                                                    <ul class="pagination pagination-rounded mb-sm-0"></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default AddBlogsTags