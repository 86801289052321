import AdminHeader from "../../component/Header"
import Sidebar from "../../component/Sidebar"
import { ApiService } from "../../component/Services/apiservices"
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
const CustomerDetail=()=>{
    const [Userdata, setUserdata] = useState({})
    const {id}= useParams()
    const didMountRef = useRef(true)
    let apiServices = new ApiService();
    useEffect(() => {

        if (didMountRef.current) {
            getalluserlist()
        }
        didMountRef.current = false;
    }, [])

    const getalluserlist = () => {
        const dataString={
            _id:id
        }
        apiServices.getuserdetailrequest(dataString).then(res => {
            if (res?.data?.status == "success") {
                setUserdata(res.data.UserData)
            }
        }).catch((error) => { })
    }
    return(<>
     <div id="layout-wrapper">
            <AdminHeader />
            <Sidebar />
            <div class="main-content">
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <div>
                                        <h4 class="mb-sm-0">Manage Customers</h4>
                                        <ol class="breadcrumb mt-2">
                                            <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                            <li class="breadcrumb-item active">Customers Detail</li>
                                        </ol>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">

                                    <div class="card-body">
                                       <div>
                                        <p>Name:<span>{Userdata.user_name}</span></p>
                                        <p>Email:<span>{Userdata.user_email}</span></p>
                                        <p>Mobile:<span>{Userdata.user_mobileno}</span></p>
                                        <p>Address:<span>{Userdata.user_addres}</span></p>
                                        <p>Property Count:<span>{Userdata.property_count}</span></p>
                                        <p>Status:<span>{Userdata.user_admin_status}</span></p>
                                       </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div >

        </div >
    
    </>)
}

export default CustomerDetail