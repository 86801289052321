import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../component/Services/apiservices";
import { toast } from "react-toastify";

const FooterSection = () => {
    let apiServices = new ApiService();
    const editorRefs = useRef({});
    const didMountRef = useRef(true);
    const [footerData, setFooterData] = useState({});
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [footerdescrip , setFooterDescriptions] =useState({
        'editor1':'',
        'editor2':'',
        'editor3':'',
        'editor4':''
    })

    useEffect(() => {
        const loadScript = (src, callback) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = callback;
            document.body.appendChild(script);
        };

        const initializeCKEditor = () => {
            ['editor1', 'editor2', 'editor3', 'editor4'].forEach(editorId => {
                const editorElement = document.getElementById(editorId);
                if (editorElement && !editorRefs.current[editorId]) {
                    window.CKEDITOR.replace(editorElement);
                    const editorInstance = window.CKEDITOR.instances[editorId];
                    editorRefs.current[editorId] = editorInstance;

                    editorInstance.on('instanceReady', () => {
                        editorInstance.on('change', () => {
                            const data = editorInstance.getData();
                            setFooterDescriptions((prevState) => ({
                                ...prevState,
                                [editorId]: data,
                            }));
                        });

                        editorInstance.on('mode', () => {
                            if (editorInstance.mode === 'source') {
                                editorInstance.on('contentDom', () => {
                                    editorInstance.document.on('keyup', () => {
                                        const data = editorInstance.getData();
                                        setFooterDescriptions((prevState) => ({
                                            ...prevState,
                                            [editorId]: data,
                                        }));
                                    });
                                });
                            } else {
                                editorInstance.on('contentDom', () => {
                                    editorInstance.document.on('keyup', () => {
                                        const data = editorInstance.getData();
                                        setFooterDescriptions((prevState) => ({
                                            ...prevState,
                                            [editorId]: data,
                                        }));
                                    });
                                });
                            }
                        });
                    });
                }
            });
            setIsEditorReady(true);
        };

        loadScript('https://cdn.ckeditor.com/4.12.1/full-all/ckeditor.js', () => {
            const checkCKEditorLoaded = setInterval(() => {
                if (window.CKEDITOR) {
                    clearInterval(checkCKEditorLoaded);
                    initializeCKEditor();
                }
            }, 100);
        });

        return () => {
            Object.values(editorRefs.current).forEach(editorInstance => {
                if (editorInstance) {
                    editorInstance.destroy();
                }
            });
            editorRefs.current = {};
        };
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            getFooterData();
        }
        didMountRef.current = false;
    }, []);

    const getFooterData = () => {
        apiServices.getfooterdatarequest().then(res => {
            if (res?.data?.status === "success") {
                setFooterData(res.data.FooterData);
                setFooterDescriptions({
                    'editor1': res.data.FooterData.footer1_descrip || '',
                    'editor2': res.data.FooterData.footer2_descrip || '',
                    'editor3': res.data.FooterData.footer3_descrip || '',
                    'editor4': res.data.FooterData.footer4_descrip || '',
                });
            }
        }).catch((error) => { });
    };

  

    useEffect(() => {
        if (isEditorReady && footerData) {
            if (editorRefs.current['editor1']) {
                editorRefs.current['editor1'].setData(footerData.footer1_descrip || '');
            }
            if (editorRefs.current['editor2']) {
                editorRefs.current['editor2'].setData(footerData.footer2_descrip || '');
            }
            if (editorRefs.current['editor3']) {
                editorRefs.current['editor3'].setData(footerData.footer3_descrip || '');
            }
            if (editorRefs.current['editor4']) {
                editorRefs.current['editor4'].setData(footerData.footer4_descrip || '');
            }
        }
    }, [isEditorReady, footerData]);

  
    const handleSubmit=()=>{
        const dataString={
            footer_id:footerData._id,
            footer1_descrip:footerdescrip.editor1,
            footer2_descrip:footerdescrip.editor2,
            footer3_descrip:footerdescrip.editor3,
            footer4_descrip:footerdescrip.editor4,
        }
       apiServices.getappearancefooterupdaterequest(dataString).then((res)=>{
        if(res.data.status=='success'){
            toast.success(res?.data?.message);
            setTimeout(()=>{
                getFooterData()

            })
        }
        else {
             toast.error(res?.data?.message)
        }
       }).catch((error)=>{

       })
    }

    return (
        <div id="layout-wrapper">
            <AdminHeader />
            <Sidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Manage Footer</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                            <li className="breadcrumb-item active">Footer</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <form> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Add Footer</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6>Footer1:</h6>
                                            <div className="live-preview">
                                                <div className="row gy-4">
                                                    <div className="col-xxl-12 col-md-12">
                                                        <textarea className="ckeditor-classic" id="editor1" name="footer1_descrip"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Add Footer</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6>Footer2:</h6>
                                            <div className="live-preview">
                                                <div className="row gy-4">
                                                    <div className="col-xxl-12 col-md-12">
                                                        <textarea className="ckeditor-classic" id="editor2" name='footer2_descrip'></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Add Footer</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6>Footer3:</h6>
                                            <div className="live-preview">
                                                <div className="row gy-4">
                                                    <div className="col-xxl-12 col-md-12">
                                                        <textarea className="ckeditor-classic" id="editor3" name='footer3_descrip'></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Add Footer</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6>Footer4:</h6>
                                            <div className="live-preview">
                                                <div className="row gy-4">
                                                    <div className="col-xxl-12 col-md-12">
                                                        <textarea className="ckeditor-classic" id="editor4" name='footer4_descrip'></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* </form> */}
                        <div className="row">
                            <div class="col-lg-12">
                                <div class="text-end">
                                    <button type="submit" class="btn btn-primary" onClick={()=>{handleSubmit()}}>Update</button>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterSection;
