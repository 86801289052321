import AdminHeader from "../../component/Header"
import Sidebar from "../../component/Sidebar"
import { ToastContainer, toast } from 'react-toastify';
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import React, { useRef, useState, useEffect } from "react";
import constant from "../../component/Services/constant";
const AdminFaq = () => {
    let apiServices = new ApiService();
    const didMountRef = useRef(true)
    const [edititemdata, setedititemdata] = useState({})
    const [faqId, setfaqId] = useState('')
    const [faqData, setfaqData] = useState([])
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();
    const onSubmit = (data) => {
     
       const dataString={
        faq_title:data.faq_title,
        faq_description:data.faq_description
       }
        if (faqId !== '') {
            dataString.faq_id = faqId;
        }
    
        apiServices.getfaqaddrequest(dataString)
            .then(res => {
                if (res.data.status === "success") {
                    toast.success(res?.data?.message);
                    getFaqList();
                    setValue("faq_description", '');
                    setValue("faq_title", '');
                }
                else if (res.data.status === "error"){
                
                    toast.error(res?.data?.message);
                }
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                // Handle error
            });
    };

    const changestatus=(data )=>{
        const requestBody = {
            faq_title: data.faq_title,
            faq_status:data.faq_status==1?0:data.faq_status==0?1:1,
            faq_id:data._id,
            faq_description:data.faq_description,
        };
        apiServices.getfaqaddrequest(requestBody).then(res => {
            if (res.data.status === "success") {
                getFaqList()
                setValue("faq_title", '');
                setValue("faq_status", '');
             
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    }

   
    useEffect(() => {

        if (didMountRef.current) {
            getFaqList()
        }
        didMountRef.current = false;
    },[])

    useEffect(()=>{
        if(edititemdata){
            setValue("faq_title", edititemdata?.faq_title);
            setValue("faq_description", edititemdata?.faq_description);
           
        }
    },[edititemdata])

    const getFaqList = () => {
        apiServices.getfaqlistrequest().then(res => {
            if (res?.data?.status == "success") {
                setfaqData(res.data.FaqData)
            }
        }).catch((error)=>{})
    }
   

    const removeFaq = (itemid) => {
        const dataString = {
            faq_id: itemid
        }
        const confirmed = window.confirm("Are you sure you want to Delete this Faq");
        if(confirmed){
            apiServices.getfaqdeleterequest(dataString).then((res) => {
                if (res?.data?.status == "success") {
                    toast.success(res.message)
                    setTimeout(()=>{
                        getFaqList()
                    }, 500) 
                }
                else{
                    toast.error(res?.data?.message)
                }
            }).catch((error)=>{})
        }
        

    }


    return (<>
        <ToastContainer />
        <div id="layout-wrapper">

            <AdminHeader />
            <Sidebar />
            <div class="main-content">
                <div class="page-content">
                    <div class="container-fluid">

                        <div class="row" id="table-striped">
                            <div class="col-4">
                                <div class="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Add New FAQ</h4>

                                    </div>
                                    <div class="card-body">
                                        <div class="live-preview">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div class="row gy-4">
                                                    <div class="col-lg-12">
                                                      
                                                            <label for="basiInput" class="form-label">Faq Title:</label><span className="text-danger">*</span>
                                                            <input type="text" class="form-control" name="faq_title"
                                                                {...register("faq_title", {
                                                                    required: true,

                                                                })}
                                                            />
                                                            {errors.faq_title && errors.faq_title.type === "required" && (
                                                                <small className="text-danger">Faq Title is required.</small>
                                                            )}
                                                      
                                                    </div>

                                                    <div class="col-xxl-12 col-md-12">
                                                       
                                                        <label for="basiInput" class="form-label">Faq Description:</label><span className="text-danger">*</span>
                                                            <textarea type="text" class="form-control" name="faq_description" rows="5"
                                                                {...register("faq_description", {
                                                                    required: true,

                                                                })}
                                                            />
                                                            {errors.faq_description && errors.faq_description.type === "required" && (
                                                                <small className="text-danger">Faq Description is required.</small>
                                                            )}
                                                       
                                                    </div>
                                                  



                                                    <div class="col-lg-12">
                                                        <div class="text-end">
                                                            <button type="submit" class="btn btn-primary">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div class="col-8">
                                <div class="card">
                                    <div class="card-header">
                                        <h5>FAQ Listings</h5>
                                    </div>
                                    <div class="card-body p-0" >

                                        <div class="live-preview">
                                            <table class="table table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">FAQ Name</th>
                                                        <th scope="col">Created At</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>


                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {faqData?.map((items, index) => {
                                                        return (<>

                                                            <tr>

                                                                <td class="fw-semibold">{index + 1}</td>
                                                             
                                                                <td>{items.faq_title}</td>
                                                                <td>{moment(items?.createdAt).format(`LL`)}</td>
                                                                <td >{items?.faq_status == 0 ?
                                                                    <Badge bg="danger" onClick={()=>{changestatus(items)}}>Inactive</Badge> : items.faq_status == 1 ? <Badge bg="success" onClick={()=>{changestatus(items)}}>Active</Badge> : ''}</td>
                                                                <td className="  text-warning fs-5"> <i className="mdi mdi-lead-pencil" onClick={() => { setfaqId(items._id); setedititemdata(items) }}></i> <i class="mdi mdi-trash-can" onClick={() => { removeFaq(items._id) }}></i> </td>

                                                            </tr>



                                                        </>)
                                                    })}
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                    {/* <div class="card-footer bg-white">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div>
                                                    <p class="mb-sm-0 mt-2">Showing 1 to 1 of 1 entries</p>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="float-sm-end">
                                                    <ul class="pagination pagination-rounded mb-sm-0"></ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                           
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default AdminFaq