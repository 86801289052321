import React , {useState , useEffect , useRef} from "react";
import { ApiService } from "../../component/Services/apiservices";
import {  useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let apiServices = new ApiService()
function AdminHome(){
const didMountRef = useRef(true)
const [userName , setUserName] = useState("")
const [userPassword , setUserPassword] = useState("")
const Navigate = useNavigate()
    useEffect(() => {

        if (didMountRef.current) {
            if(JSON.parse(localStorage.getItem('USER_DATA')) !== null){
                Navigate("/csadmin/dashboard")
              }
              else{
                
              }
        }
        didMountRef.current = false;
    })

    const loginCheck =()=>{
        if(userName == ""){
            toast.error("Email is Required")
            return false
        }
        if(userPassword == ""){
            toast.error("Password is required")
            return false
        }
       
        const dataString = {
            username :  userName , 
            password : userPassword

        }
  apiServices.getadminloginPostRequest(dataString).then(res=>{
    if(res.data.status == "success"){
       localStorage.setItem('USER_DATA',JSON.stringify(res.data.data))
        Navigate("/csadmin/dashboard")
    }
    else if(res.data.status == "error"){
     toast.error(res.data.message)
    }

  })
    }
    return(
        <>
  <ToastContainer/>
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div class="bg-overlay"></div>
        <div class="auth-page-content overflow-hidden pt-lg-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card overflow-hidden">
                            <div class="row g-0">
                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                                        <div class="bg-overlay"></div>
                                        <div class="position-relative h-100 d-flex flex-column">
                                            <div class="mb-4">
                                                <a href="index.html" class="d-block">
                                                    <img src="/img/logo.png" alt="" height="18"/>
                                                </a>
                                            </div>
                                            <div class="mt-auto">
                                                <div class="mb-3">
                                                    <i class="ri-double-quotes-l display-4 text-success"></i>
                                                </div>

                                                <div id="qoutescarouselIndicators" class="carousel slide" data-bs-ride="carousel">
                                                    <div class="carousel-indicators">
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                    </div>
                                                   
                                                </div>
                                       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        

                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4">
                                        <div>
                                            <h5 class="text-primary">Welcome Back !</h5>
                                            <p class="text-muted">Sign in to continue to Proptrail.</p>
                                        </div>

                                        <div class="mt-4">
                                            <form action="index.html">

                                                <div class="mb-3">
                                                    <label for="username" class="form-label">Username</label>
                                                    <input type="text" class="form-control" id="username" onChange={(e)=>setUserName(e.target.value)} placeholder="Enter username"/>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="username" class="form-label">Password</label>
                                                    <input type="password" class="form-control" id="password" onChange={(e)=>setUserPassword(e.target.value)} placeholder="Enter password"/>
                                                </div>

                                          

                                                <div class="mt-4">
                                                    <button class="btn btn-success w-100" type="button" onClick={loginCheck}>Sign In</button>
                                                </div>

                                                {/* <div class="mt-4 text-center">
                                                    <div class="signin-other-title">
                                                        <h5 class="fs-13 mb-4 title">Sign In with</h5>
                                                    </div>

                                                    <div>
                                                        <button type="button" class="btn btn-primary btn-icon waves-effect waves-light"><i class="ri-facebook-fill fs-16"></i></button>
                                                        <button type="button" class="btn btn-danger btn-icon waves-effect waves-light"><i class="ri-google-fill fs-16"></i></button>
                                                        <button type="button" class="btn btn-dark btn-icon waves-effect waves-light"><i class="ri-github-fill fs-16"></i></button>
                                                        <button type="button" class="btn btn-info btn-icon waves-effect waves-light"><i class="ri-twitter-fill fs-16"></i></button>
                                                    </div>
                                                </div> */}

                                            </form>
                                        </div>

                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0">&copy;
                                <script>document.write(new Date().getFullYear())</script> Proptrail. Crafted with <i class="mdi mdi-heart text-danger"></i> by Codexo Software
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
 
        </>
    )
}
export default AdminHome